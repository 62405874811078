@use '../../abstracts' as *;

.ticketsTappa {
  .alert {
    text-decoration: underline $yellow;
  }
  .links {
    margin: 3em auto;
    @include from-to(small, medium) {
      flex-direction: column;
      gap: 1em;
    }

    display: flex;
    justify-content: space-around;
    a {
      font-size: clamp(1em, 1.5vw, 1.5em);
    }
  }
  .ticket {
    margin: 3em auto;
    display: flex;
    flex-direction: column;
    gap: 2em;
    h1 {
      color: $yellow;
    }
    img {
      width: 100%;
    }
    .content {
      align-content: center;
      width: 100%;
      .costo {
        display: flex;
        gap: 0.5em;
        .price {
          display: inline-block;
          color: $yellow;
          font-weight: bold;
          margin: 0;
        }
      }
      .indirizzo {
        span {
          text-decoration: underline $yellow;
          padding: 0 0.5em;
          @include from-to(small, medium) {
            display: block;
            line-height: 2em;
          }
        }
        gap: 0.3em;
        margin-bottom: 0.6em;
      }
    }
    .titleDesktop {
      display: none;
    }
    @include from-to(small, medium) {
      gap: 0.5em;
      a {
        display: block;
        text-align: center;
      }
    }
    @include responde-to(medium) {
      &:nth-child(odd) {
        .content {
          order: 1;
          text-align: right;
          justify-content: right;
        }
        img {
          order: 2;
        }
      }
      .titleDesktop {
        display: block;
      }
      .titleMobile {
        display: none;
      }
      flex-direction: row;
      img {
        width: 40%;
      }
    }
  }
}
