@use '../../abstracts' as *;

.spectatorTicket {
  .qrReader {
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 90%;

    border: 2px solid $yellow;
  }

  .toggleQr {
    display: block;
    margin: 1em auto;
  }

  .alreadyEntered {
    background-color: $red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    height: 40vh;
    margin: 2em auto;
    text-align: center;
    font-family: $fontAkira;
  }

  .entering {
    margin: 2em auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $black;
    color: $yellow;
    border: 2px solid $yellow;
    padding: 1em;
    height: 40vh;
    text-align: center;
    font-family: $fontAkira;
  }

  .nextQr {
    display: block;
    margin-right: 0;
    margin-left: auto;
    .icon {
      margin: -3px;
    }
  }
}
