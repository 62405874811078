@use '../abstracts' as *;

.loginForm {
  margin: 0 auto;
  .socialLogin {
    display: flex;
    width: 40%;
    margin: 0 auto;
    justify-content: center;
    gap: 1em;
    button {
      cursor: pointer;
      padding: 0;
      display: flex;
      font-size: 1.4em;
      padding: 0.4em;
      border: none;

      &.facebook {
        color: $white;
        background-color: $facebookBlue;
      }
    }
  }

  .forgotPassword {
    text-align: right;
    display: block;
    margin: 0;
    padding: 0;
    font-size: 0.7em;
  }
}
