@use '../../abstracts' as *;

.accountPage {
  margin: 1em auto;
  .head {
    display: flex;
    justify-content: right;
    gap: 5em;
  }

  .insurance {
    border: 2px solid $white;
    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }
    span {
      font-weight: bold;
      color: $yellow;
    }
  }

  .orders,
  .tickets {
    margin: 4em auto;
    .showRecap {
      width: 100%;
      display: flex;
      justify-items: center;
      align-items: center;
      margin: 1em 0;
      color: $white;
      border-left: 2px solid $yellow;
      height: 2.5em;
      .text {
        margin: 0 auto 0 1em;
        line-height: 1.5em;
      }

      .arrow {
        font-size: 1.5em;
        margin: 0 0.5em 0 0;
      }

      &.up {
        .arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
  .tickets {
    .ticket {
      display: flex;
      gap: 2em;
      @include from-to(small, medium) {
        flex-direction: column;
      }
      img {
        width: 150px;
      }
    }
  }

  .order {
    border-left: 1px solid $white;
    padding: 1em;
    margin: 1em auto;
    img {
      width: 4em;
    }
    .info {
      width: 100%;
      display: flex;
      justify-items: center;
      align-items: center;
      h4 {
        margin: 0 auto 1em 0;
        line-height: 1.5em;
      }

      small {
        margin: 0;
      }
    }

    .recap {
      @include from-to(small, medium) {
        &.hide {
          display: none;
        }
        &.show {
          width: 90%;
          margin: 0 auto;
          display: block;
        }
      }
    }

    .subtotale,
    .totale {
      div {
        display: flex;
        margin: 1em auto;
        font-weight: bold;
        p {
          margin: 0 0 0 auto;
        }
      }
    }
  }
}

.settingsPage {
  nav {
    display: flex;
    gap: 1.5em;
    margin: 1em auto;
    a.active {
      text-decoration: underline $yellow;
    }
  }
}
