@use '../../abstracts/' as *;

.contattiPage {
  overflow-x: hidden;
  .head {
    img.logo {
      @include from-to(small, medium) {
        width: 90%;
      }
      @include responde-to(medium) {
        width: 60%;
      }
      display: block;
      margin: 1.5em auto;
    }
    p {
      text-align: center;
      font-family: $fontAkira;
      color: $yellow;
    }
  }
  .toPartners {
    display: block;
    text-align: center;
    margin: 2em auto;
  }
  .body {
    margin: 2em auto;
    display: flex;
    // justify-content: space-between;
    justify-items: center;

    @include from-to(small, medium) {
      flex-direction: column;
    }
    h3 {
      text-decoration: double underline $yellow;
    }
    .contacts {
      width: 100%;

      @include responde-to(medium) {
        width: 45%;
        margin: 0 auto;
        text-align: center;
        div {
          padding: 0.5em 0;
          margin: 0 auto;
          width: fit-content;
        }
      }

      p,
      a {
        display: flex;
        align-items: center;
        line-height: 1em;
        gap: 0.5em;
      }
    }

    .contactForm {
      width: 100%;
      margin: 0 auto;

      .error {
        color: $red;
      }

      .message {
        color: $yellow;
      }

      form {
        margin: 0 auto;
      }
      @include responde-to(medium) {
        width: 45%;
      }
    }
  }
}
