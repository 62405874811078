@use '../../abstracts/' as *;

.athleteInfoPage {
  h1 {
    text-align: center;
  }
  & > small {
    display: block;
    text-align: center;
  }
  form {
    option:disabled {
      color: gray;
    }
    small {
      display: block;
      margin-top: -1.5em;
      margin-bottom: 1.5em;
    }
    hr {
      display: block;
      margin: 2em 0;
    }

    input[type='submit'] {
      margin: 2em 0;
    }
  }

  .deleteTicketInText{
    color: $yellow;
  }
}
