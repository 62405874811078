@use '../../abstracts' as *;

.loginPage {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  .title {
    h1 {
      margin: 0.2em auto;
      font-size: 2em;
    }
    margin: 2em auto 1em auto;
  }
  .register {
    a {
      color: $yellow;
      font-weight: bold;
    }
  }
  @include responde-to(medium) {
    .loginForm {
      width: 40%;
    }
  }
}
