@use '../../abstracts' as *;

.ticketPage {
  input[type='submit'] {
    &:disabled {
      opacity: 0.7;
    }
  }
  p {
    margin: 0 auto 2em auto;
  }
}

.ticketTappa {
  .alert {
    text-decoration: underline $yellow;
  }
  display: flex;
  flex-direction: column;
  .ticketRecap {
    width: 90%;
    margin: 0 auto;
    h1 {
      @include from-to(small, medium) {
        text-align: center;
        margin: 1em auto 0 auto;
      }
      span {
        color: $yellow;
      }
    }
    section {
      margin: 2em 0;
    }
    .content {
      h2 {
        margin-top: 0;
      }
      display: flex;
      flex-direction: column;
      gap: 2em;
      .image-container {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
      }

      img {
        width: 100%;
        aspect-ratio: 1 / 1;
      }
      @include responde-to(medium) {
        flex-direction: row;
        img {
          aspect-ratio: 1 / 1;
          width: 40%;
          height: 40%;
        }
      }
    }

    .textAssoluto {
      padding: 0 0 2em 0;
      margin: 2em 0;
    }

    .price {
      display: inline-block;
      color: $yellow;
      font-weight: bold;
      margin: 0;
    }

    .original {
      display: inline-block;
      text-decoration: line-through;
      padding: 0 0.5em;
    }
    line-height: 1.3em;

    .tornaShop {
      margin: 1em 0;
      display: block;
      font-weight: bold;
    }
  }
}
