@use '../../abstracts' as *;

.termsPage {
  color: $black;
  background-color: $white;
  .pageTitle {
    color: $white;
    background-color: $black;
    padding-bottom: 1em;
  }
}
