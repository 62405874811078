@use '../../abstracts' as *;

.regolamentoPage {
  text-align: center;
  & > section {
    margin: 2em auto;
    p {
      font-size: clamp(1.2rem, 1.5vw, 1.5rem);
      line-height: 1.5em;
      margin: 2em auto;
    }
  }
  .regolamento {
    font-family: $fontAkira;

    .countdown {
      div {
        background-color: $black;
      }
    }

    p {
      color: $yellow;
    }
  }
  .assicurazione {
    img {
      width: 100%;
    }
    @include responde-to(medium) {
      img {
        width: 60%;
        margin: 0 auto;
      }
    }
  }
}
