@use '../abstracts' as *;

#checkoutSteps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2em auto 2em auto;
  div {
    font-size: clamp(0.8rem, 2vw, 1rem);
    a {
      padding: 0.5em;
      &.active {
        font-weight: bold;
      }
    }
    p {
      padding: 0.5em;
      display: inline;
      color: rgba($color: #fff, $alpha: 0.5);
    }
    .icon {
      color: $yellow;
      vertical-align: baseline;
      margin: -4px;
    }
  }
}
