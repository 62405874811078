@use '../abstracts' as *;

.countdown {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  div {
    display: flex;
    margin: 0 auto;
    font-weight: bold;
    flex-direction: column;
    justify-content: center;

    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.7);
    color: $yellow;

    small {
      display: block;
      font-size: 0.5em;
      margin: 0.5em auto 0 auto;
    }
  }

  @include from-to(small, medium) {
    width: 95%;
    div {
      width: 4em;
      height: 4em;
      font-size: 1.3em;
    }
  }

  @include responde-to(medium) {
    width: 60%;
    div {
      width: 4em;
      height: 4em;
      font-size: 2em;
    }
  }
}
