@use '../abstracts/' as *;

.checkoutSummary {
  section.coupon {
    width: 95%;
    margin: 2em auto 1em auto;
    h3 {
      margin: 0;
    }
    hr {
      @include responde-to(medium) {
        display: none;
      }
    }
  }
  p.coupon {
    span {
      color: $yellow;
    }
    .remove {
      color: $yellow;
      background-color: $black;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }

  form.coupon {
    width: 100%;
    display: flex;
    gap: 0.5em;
    margin: 0;
    section {
      margin-bottom: 0;
      width: 75%;
    }

    input[type='submit'] {
      color: $black;
      background-color: $yellow;
      border: none;
      width: 25%;
      font-size: 0.8em;
      padding: 0;
      font-weight: bold;
    }
  }

  .showRecap {
    @include from-to(small, medium) {
      width: 100%;
      display: flex;
      justify-items: center;
      align-items: center;
      margin: 1em 0;
      color: $white;
      border-left: 2px solid $yellow;
      height: 2.5em;
      .text {
        margin: 0 auto 0 1em;
        line-height: 1.5em;
      }
      .cart {
        font-size: 1.5em;
        margin: 0 0.5em;
      }
      .total {
        padding: 0 0.5em 0 0;
        text-align: right;
        display: block;
      }

      .arrow {
        font-size: 1.5em;
        margin: 0 0.5em 0 0;
      }
      &.up {
        .arrow {
          transform: rotate(180deg);
        }
      }
    }

    @include responde-to(medium) {
      display: none;
    }
  }

  .recap {
    hr {
      background-color: $white;
    }
    @include from-to(small, medium) {
      &.hide {
        display: none;
      }
      &.show {
        width: 90%;
        margin: 0 auto;
        display: block;
      }
    }
  }

  .subtotale,
  .totale {
    div {
      display: flex;
      margin: 1em auto;
      p {
        margin: 0 0 0 auto;
      }
    }
  }
}
