@use '../abstracts' as *;
.homePage {
  width: 100%;

  .youtubeLive {
    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }
  }

  & > section {
    text-align: center;
    margin: 4em auto;

    h2 {
      font-size: clamp(2em, 1.1vw, 3em);
      font-family: $fontAkira;
      text-decoration: underline $yellow;
    }

    &.head {
      margin-top: 0;
      font-size: clamp(1em, 1.4vw, 1.6em);
      font-weight: bold;
      font-family: $fontAkira;
      background-image: url($images + '/background/home.jpg');
      background-size: cover;
      background-position: center;

      @include from-to(small, medium) {
        min-height: 65vh;
        padding: 4em 0;
      }
      @include responde-to(medium) {
        padding: 2em 0;
      }

      img.logo {
        width: 80%;
        display: block;
        margin: 0 auto;
      }

      .scintilla {
        font-size: clamp(0.95em, 2vw, 1.6em);
        color: $yellow;
        display: inline-block;
        padding: 0.5em 1em;
        margin: 1.5em auto 0.5em auto;
      }

      .countdown {
        div {
          color: $white;
          border: 1px solid $white;
        }
      }

      .campionato {
        font-size: clamp(0.8em, 1.4vw, 1.6em);
        display: inline-block;
        margin: 1.5em auto 4em auto;
        line-height: 2em;
        padding: 0.5em 1em;
        span.gara {
          font-size: clamp(1.4em, 1.4vw, 1.9em);
          color: $yellow;
        }
        .green {
          border-bottom: 2px solid green;
        }
        .white {
          border-bottom: 2px solid white;
        }
        .red {
          border-bottom: 2px solid red;
        }
      }

      .btn {
        margin: 0 auto;
        display: block;
        font-size: clamp(1em, 1.5vw, 1.5em);
        &:hover {
          transform: scale(1.1);
          transition: transform 0.5s ease-in-out;
        }
        @include from-to(small, medium) {
          width: 95%;
        }
        @include responde-to(medium) {
          width: 60%;
        }
      }
    }
    .nogravity {
      margin: 1em auto;
      img {
        width: 100%;
      }
    }
    .cef {
      img {
        width: 50%;
        margin: 1em auto 0 auto;
      }
    }
    .partners {
      display: flex;
      padding: 0 1em;

      .react-multi-carousel-item {
        display: flex;
        justify-content: center;
      }
      .fuck {
        max-width: 5em;
      }
      .asi {
        max-width: 6em;
      }
      img {
        align-self: center;
        max-width: 7em;
        @include from-to(small, medium) {
        }

        @include responde-to(medium) {
        }
      }
    }

    &.events {
      h2 {
        font-size: clamp(2em, 1.1vw, 3em);
        font-family: $fontAkira;
        text-decoration: underline $yellow;
      }
      div {
        display: flex;
        gap: 0.5em;
        img {
          box-shadow: 0 0 15px 10px rgba($color: black, $alpha: 0.4);
          width: 100%;
          height: 100%;
          object-fit: cover;

          &:hover {
            transform: scale(1.1);
            transition: transform 0.5s;
          }
        }

        @include from-to(small, posts) {
          flex-direction: column;
          gap: 1.5em;
          margin: 0 auto;
        }
      }
    }

    &.coppa {
      img {
        box-shadow: 0 0 15px 10px rgba($color: black, $alpha: 0.4);
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 25em;

        &:hover {
          transform: scale(1.1);
          transition: transform 0.5s;
        }
      }
    }

    &.assoluto {
      img {
        width: 100%;
      }
    }

    &.shop {
      .go-to {
        color: $yellow;
        display: block;
        font-family: $fontAkira;
        text-align: right;
        padding: 0 1em;
        margin: 1.5em 0;
        font-size: clamp(1em, 1.5vw, 2em);

        &:hover {
          text-decoration: underline;
          transition: transform 0.5s ease-in-out;
        }
      }
      .btn {
        &:hover {
          transform: scale(1.1);
          transition: transform 0.5s ease-in-out;
        }
      }
      .yellow {
        color: $yellow;
        font-family: $fontAkira;
      }
    }

    &.social {
      h2 {
        margin: 4em auto 3em auto;
      }

      .socialCard {
        width: 20em;
        padding: 2em 1em;
        display: inline-block;
        margin: 4em auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: $fontAkira;
        border-top: 2px solid $white;
        border-bottom: 2px solid $white;
        .instagram,
        .youtube {
          margin-top: -70px;
        }
        h4 {
          margin: 1em;
          color: $yellow;
          letter-spacing: 0.8px;
        }
        .btn {
          margin: 1em auto;
          font-style: oblique;
          border-top-right-radius: 10px;
        }
      }

      .instagram,
      .youtube {
        --size: 100px;
        display: inline-block;
        width: var(--size);
        height: var(--size);
        line-height: var(--size);
        font-size: var(--size);
        text-align: center;
        border-radius: calc(var(--size) / 4);
        color: #fff;
        vertical-align: middle;
        background: #d6249f;
        background: radial-gradient(
          circle at 30% 107%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
      }

      .youtube {
        --size: 100px;
        display: inline-block;
        width: var(--size);
        height: var(--size);
        line-height: var(--size);
        font-size: var(--size);
        text-align: center;
        border-radius: calc(var(--size) / 4);
        color: #f00;
        vertical-align: middle;
        background: #fff;
      }
    }
  }
}
