@use '../abstracts/' as *;

.btn {
  background-color: $yellow;
  color: $black;
  border: 2px solid $yellow;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5em 1.25em;
  font-family: $fontAkira;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: $black;
    color: $yellow;
  }

  &-reversed {
    background-color: $black;
    color: $yellow;
    border: 2px solid $yellow;

    &:hover {
      background-color: $yellow;
      color: $black;
      border: 2px solid $yellow;
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
