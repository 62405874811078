@use '../abstracts' as *;

.background {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  &.bgimg {
    display: none;
    &.loaded {
      display: block;
    }
    &.error {
      display: none;
    }
  }
}

.countdownPage {
  font-family: $fontAkira;
  .logo {
    width: 70%;
    margin: 4em auto 1em auto;
    display: block;
  }

  & > small,
  & > p {
    text-align: center;
    display: block;
    color: $yellow;
  }

  & > p {
    font-weight: bold;
    margin: 1em auto 4em auto;
    text-transform: uppercase;
  }

  & > small {
    margin: 2em auto;
  }

  .official {
    width: 90%;
    margin: 1em auto 0 auto;
    text-align: center;
    color: white;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 0.9em;

    @include from-to(small, medium) {
      margin: 2em auto 0 auto;
    }
  }

  section.underCount {
    width: 85%;
    margin: 6em auto 0 auto;

    @include responde-to(medium) {
      margin: 5em auto 0 auto;
    }

    p {
      text-shadow: 0 0 50px black;
      text-align: center;
      font-size: 1.5em;
      line-height: 2em;
      margin: 2em auto 0 auto;
      span {
        font-size: clamp(1.5em, 2.5vw, 2.5em);
        display: block;
        @include responde-to(medium) {
          display: inline;
        }
        color: $yellow;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
