@use '../../abstracts' as *;

.cartPage {
  margin: 1em auto;
  .emptyCart {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    h2 {
      font-size: 2em;
      margin: 1.5em 0;
    }
    .btn {
      font-size: clamp(1em, 2vw, 1.5em);
    }
  }
  .tableTitles {
    @include from-to(small, medium) {
      display: grid;
      grid-template-columns: 6em 2fr 1fr;

      p:not(.totalTitle) {
        display: none;
      }
      p.totalTitle {
        grid-column: 3 / -1;
        justify-self: center;
      }
    }
    @include responde-to(medium) {
      display: grid;
      grid-template-columns: 6em 2fr 1fr 1fr 1fr 1fr;
      text-align: center;
      font-weight: bold;
      width: 98%;
      margin: 3em auto 0 auto;
      p {
        margin: 0.5em 0;
        &.product {
          grid-column: 1 / 3;
          text-align: left;
        }
      }
    }
  }

  hr {
    background-color: $yellow;
    height: 1px;
    border: none;
  }

  .total {
    display: grid;
    margin: 0 auto;

    .separator {
      position: relative;

      .thunder {
        color: $yellow;
        background-color: $black;
        position: absolute;
      }
    }

    hr {
      width: 100%;
    }

    p {
      text-align: center;
      justify-self: center;
      font-weight: bold;
    }

    @include from-to(small, medium) {
      grid-template-columns: 6em 2fr 1fr;
      grid-template-rows: 1.5em 1fr 1fr;

      .separator {
        grid-column: 3 / -1;
        grid-row: 1 / 2;
        .thunder {
          font-size: 1.2em;
          left: calc(50% - 0.6em);
        }
      }

      p {
        grid-column: 3 / -1;
        grid-row: 2/ 3;
      }

      small {
        grid-column: 2/ -1;
        grid-row: 3 / -1;
        text-align: right;
      }
    }
    @include responde-to(medium) {
      grid-template-columns: 6em 2fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1.5em 1fr 1fr;
      width: 98%;
      .separator {
        grid-column: 5 / 6;
        grid-row: 1 / 2;
        .thunder {
          position: absolute;
          left: 40%;
          width: 20%;
          font-size: 1.5rem;
          line-height: 1.5em;
          text-align: center;
        }
      }

      p {
        grid-column: 5 / 6;
        grid-row: 2/ 3;
      }

      small {
        grid-column: 5 / 6;
        grid-row: 3 / -1;
        text-align: center;
      }
    }
  }

  .cartFooter {
    display: flex;
    margin: 1em auto;
    .returnStore {
      font-weight: bold;
      display: block;
      margin: auto 0 0 0;
      align-self: baseline;
      justify-self: start;
    }
    .btn {
      &.checkout {
        width: max-content;
        display: inline-block;
        margin: 1em 0 auto auto;
        align-self: flex-end;
      }
    }
  }
}
