@use '../../abstracts' as *;

.confirmedPage {
  margin: 2em auto;
  .btn {
    display: block;
    text-align: center;
    margin: 1em auto;

    @include responde-to(medium) {
      width: 50%;
      margin-left: 0;
    }
  }
}
