@use '../abstracts/' as *;

form {
  padding: 1em 0;
  // margin: 0 auto;

  .error {
    color: $red;
    font-weight: bold;
  }

  section {
    display: block;
    position: relative;
    margin-bottom: 1em;

    &.checkbox {
      text-align: left;
      display: flex;
      // Style checkbox
      input {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: $black;
        /* Not removed via appearance */
        margin: 0;
        border: 2px solid $yellow;
        background-color: $black;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid currentColor;
        // border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;

        &::before {
          content: '';
          width: 0.65em;
          height: 0.65em;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em $yellow;
          transform-origin: bottom left;
          clip-path: polygon(
            14% 44%,
            0 65%,
            50% 100%,
            100% 16%,
            80% 0%,
            43% 62%
          );
        }

        &:checked::before {
          transform: scale(1);
        }
      }

      label {
        font-size: 0.9em;
        padding: 0 0.5em;
        a {
          color: $yellow;
          font-weight: bold;
        }
      }
    }

    @include responde-to(medium) {
      &.half {
        width: calc(50% - 0.2em);
        display: inline-block;
        &.left {
          margin-right: 0.2em;
        }
        &.right {
          margin-left: 0.2em;
        }
      }

      &.one-third {
        width: 32%;
        display: inline-block;
        &.left {
          margin-right: 1%;
        }
        &.center {
          margin: 0 1%;
        }
        &.right {
          margin-left: 1%;
        }
      }
    }

    label:not(.checkboxLabel) {
      color: $white;
      font-size: 16px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 15px;
      top: 12px;
      padding: 0 5px;
      background: $black;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    input[type='text'],
    input[type='password'],
    input[type='email'],
    textarea,
    select {
      font-size: 16px;
      display: block;
      width: 100%;
      height: 44px;
      padding: 0 20px;
      background: $black;
      color: $white;
      border: 1px solid $white;
      box-sizing: border-box;

      &:disabled {
        color: $white;
        opacity: 0.4;

        ~ label {
          top: -8px;
          font-size: 11px;
        }
      }

      &:focus {
        outline: none;
        ~ label {
          top: -8px;
          font-size: 11px;
        }
      }

      &:autofill {
        border: 1px solid $yellow;
      }

      &:-webkit-autofill {
        border: 1px solid $yellow;
      }
    }

    textarea {
      height: 100px;
      padding: 20px 20px;
    }

    input:not(:placeholder-shown) ~ label:not(.checkboxLabel),
    textarea:not(:placeholder-shown) ~ label {
      top: -8px;
      font-size: 11px;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &::-ms-expand {
        display: none;
      }

      &:not([value='']):disabled ~ label {
        top: -8px;
        font-size: 11px;
      }
      &:not([value='']):valid ~ label {
        top: -8px;
        font-size: 11px;
      }

      &[value='']:focus ~ label {
        top: 12px;
        font-size: 11px;
      }

      &:not([multiple]):not([size]) {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'><path id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='white'/></svg>%0A");
        background-position: right 15px top 50%;
        background-repeat: no-repeat;
      }
    }

    &.wrong {
      input,
      select {
        border: 2px solid $red;
        color: $red;
      }

      label {
        font-weight: 600;
        color: $red;
      }

      select:not([multiple]):not([size]) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
      }

      .error {
        font-size: 0.8em;
        margin: 0.4em 0;
        color: $red;
      }
    }
  }

  input[type='submit'] {
    width: 100%;
  }

  .forgotPassword {
    text-align: right;
    display: block;
    margin: 0;
    padding: 0;
    font-size: 0.7em;
  }
}
