@use '../../abstracts' as *;

.store {
  margin: 1em auto;
  overflow-x: hidden;
  section {
    margin: 3em auto;
  }
  h1 {
    text-align: center;
  }

  .products {
    margin: 4em auto;
    h4 {
      text-align: center;
      margin: 1em auto 0 auto;
    }
    hr {
      width: 50%;
    }
    nav {
      margin: 0.5em auto 2em auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5em;
      a {
        width: 14em;
        font-size: clamp(1.3em, 1.2vw, 1.5em);
        text-align: center;
      }

      @include from-to(small, medium) {
        flex-direction: column;
        a {
          width: 99%;
        }
      }
    }

    div.zone {
      margin: 3em auto;
      h1 {
        margin-bottom: 0;
      }
    }
  }

  .events {
    margin: 10em auto;
  }

  .ticketsContainer {
    display: flex;
    justify-content: space-between;
    gap: 5%;
    a {
      width: 100%;
      img {
        width: 100%;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: transform 0.5s ease-in-out;
      }
    }
    @include from-to(small, medium) {
      flex-direction: column;
      gap: 1em;
    }
    @include responde-to(medium) {
      padding: 2em;
    }
  }

  .prossimamente {
    padding: 2em;
    @include responde-to(medium) {
      width: 50%;
    }
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    // gap: 1em;
    // @include from-to(small, medium) {
    //   grid-template-columns: 1fr;
    //   grid-template-rows: repeat(3, 1fr);
    // }
    div {
      border-top: 1px solid $yellow;
      border-bottom: 1px solid $yellow;
      padding: 1em 0;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
