@use '../abstracts' as *;
.sizeInput {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 0.2em;
  margin: 1em auto;
  a {
    $squareSize: 35px;
    display: inline-block;
    width: $squareSize;
    height: $squareSize;
    line-height: $squareSize;
    text-align: center;
    vertical-align: middle;
    border: 1px solid $white;
    &.active {
      background-color: rgba($color: $yellow, $alpha: 1);
      border-color: $yellow;
      color: $black;
    }
  }
}
