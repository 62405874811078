@use '../../abstracts' as *;

.productPage {
  .productInfo {
    width: 90%;
    display: flex;
    margin: 3em auto;
    @include from-to(small, medium) {
      flex-direction: column;
    }

    .smallDescription {
      text-align: right;
    }

    @include responde-to(medium) {
      .description {
        text-align: justify;
      }
    }

    .goToCart {
      color: $yellow;
      text-align: right;
      display: block;
    }

    .details {
      @include from-to(small, medium) {
        margin: 3em auto;
      }
      @include responde-to(medium) {
        width: 65%;
        margin: 0 auto;
      }
      width: 90%;
      h2 {
        font-family: $fontAkira;
        font-size: 2rem;
        margin: 0 auto;
        text-align: center;
        text-decoration: underline $yellow;
      }

      .zone {
        text-align: center;
        font-weight: bold;
        color: $yellow;
        margin-top: 0.5em;
      }

      .price {
        font-family: $fontAkira;
        span.euro {
          font-family: $font;
        }

        text-align: right;
        font-weight: bold;
        font-size: 1.9rem;
        line-height: 1.9rem;
        p,
        small {
          display: inline-block;
          padding: 0;
          margin: 0.2em 0;
        }
        .discount {
          font-size: 1rem;
          & > span {
            color: $yellow;
            font-weight: bold;
            font-size: 1.7em;
            padding: 0 0 0 0.5em;
          }
          p {
            font-weight: normal;
            text-align: left;
            text-decoration: line-through 2px $yellow;
          }
        }
      }
    }

    .btn.addToCart {
      margin: 0 auto;
      display: block;
      width: 100%;
    }

    .quantityInput {
      display: block;
      text-align: right;
      .remove {
        display: block;
        margin: 0 auto 0 0;
      }
    }

    .image-gallery {
      @include responde-to(medium) {
        width: 30%;
      }
    }
  }

  .esplora {
    text-align: center;
  }
}
