@use '../../abstracts' as *;

.competizioniPage {
  .head {
    margin: 2em auto;
    text-align: center;

    p {
      font-size: clamp(1rem, 1.5vw, 1.5rem);
    }
  }

  .recapGare {
    h3 {
      text-transform: uppercase;
      color: $yellow;
    }
    .list {
      text-transform: uppercase;
      font-weight: bold;
    }
    @include responde-to(medium) {
      gap: 2em;
      display: flex;
      justify-content: center;
      .list {
        min-height: 7em;
      }
      .completa {
        text-align: right;
      }
    }
  }

  .links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin: 3em auto;
    gap: 1em;

    @include from-to(small, medium) {
      a {
        width: 100%;
      }
      flex-direction: column;
    }
  }

  .description {
    line-height: 1.5; // More readable line-height
    font-size: clamp(1rem, 2vw, 1.5rem); // Larger font-size for larger screens
    padding: 1em; // Add some padding around the text
    // color: #333; // Darker text color for better contrast
    // font-family: Arial, sans-serif; // Use a more readable font-family
    p {
      margin-bottom: 1em; // Add some space below paragraphs
    }
    ul,
    ol {
      margin: 1em 0; // Add more vertical space around lists
      padding-left: 1em; // Add some space to the left of lists
      li {
        margin: 0.5em 0; // Add some space between list items
      }
    }
  }

  .slide {
    opacity: 0; /* start with 0 opacity */
    transform: translateX(-100%); /* start off-screen to the left */
    transition: all 0.5s ease-in-out; /* add a transition effect */

    &.slide-in {
      opacity: 1; /* animate opacity to 1 */
      transform: translateX(0); /* animate to original position */
    }
  }

  .record_premi {
    ol {
      text-align: left;
      padding: 0 0.5em;
      @include responde-to(medium) {
        padding: 0 3em;
      }
      li {
        margin: 1em 0;
      }
      span {
        color: $yellow;
        font-weight: bold;
      }
    }
  }

  .tappa,
  #assoluto {
    margin: 0 auto;
    padding: 3em 0;
    display: flex;
    .content {
      p {
        margin: 0;
        &.not-active {
          cursor: not-allowed;
        }
      }
      display: flex;
      flex-direction: column;
      gap: 1em;
      justify-content: center;
      font-size: clamp(1rem, 1.5vw, 1.5rem);

      h3 {
        margin: 0;
      }
      .indirizzo {
        span {
          text-decoration: underline $yellow;
          padding: 0 0.5em;
          @include from-to(small, medium) {
            display: block;
            line-height: 2em;
          }
        }
        gap: 0.3em;
        margin-bottom: 0.6em;
      }
    }

    @include from-to(small, medium) {
      width: 90%;
      flex-direction: column;

      .content {
        margin: 1.5em auto;
        gap: 1.5em;
        a {
          text-align: center;
        }
      }
    }

    @include responde-to(medium) {
      img {
        width: 30%;
      }

      .content {
        padding: 0 2em;
      }
    }
  }

  #assoluto {
    margin: 0 auto;
    flex-direction: column;
    width: 100%;
    img {
      margin: 0 auto;
      width: 100%;
    }

    .content {
      padding: 0;
      margin: 2em auto;
      text-align: center;
      width: 50%;

      @include from-to(small, medium) {
        width: 100%;
      }
    }
  }
}
