@use '../../abstracts' as *;
.checkoutPage {
  @include from-to(small, medium) {
    h1 {
      margin: 1em auto;
      text-align: center;
    }
  }
  @include responde-to(medium) {
    width: 90%;
  }
  .content {
    .forms {
      width: 90%;
      margin: 0 auto;
      padding: 0 1em;

      .signUpSmall {
        display: block;
        margin: 0 auto;
        text-align: center;
      }

      .anonLink {
        display: block;
        margin: 2em auto 0 auto;
      }

      .btn {
        display: block;
        margin: 0 0 0 auto;
      }

      .checkoutOptionBtns {
        display: flex;
        width: 100%;
        margin: 2em auto 2em auto;
        justify-content: center;
        .checkoutOptionBtn {
          background: none;
          border: none;
          padding: 0.75em 1.5em;
          font: inherit;
          cursor: pointer;
          outline: inherit;

          color: $white;
          border-bottom: 2px solid $black;

          &.signupLink {
            border-left: 2px solid $black;
          }
          &.loginLink {
            border-right: 2px solid $black;
          }
          &.active {
            color: $yellow;
            border-color: $yellow;
          }
        }
      }
    }
    @include responde-to(medium) {
      display: flex;
      margin: 0 auto;
      .forms {
        order: 1;
        width: 70%;
        padding: 0 2em 0 0;

        & > section {
          width: 70%;
          margin: 0 auto;
        }
      }
      .checkoutSummary {
        border-left: 1px solid white;
        padding: 0 1em;
        width: 30%;
        order: 2;
      }
    }
  }

  .returnCart {
    font-weight: bold;
    display: block;
    margin: 2em auto;
  }

  .legendCheckbox {
    display: flex;
    align-items: center;
    margin: 1em 0;
    input[type='checkbox'] {
      margin-right: 0.5em;
    }
    label {
      font-family: $fontAkira;
      small {
        font-family: $font;
      }
    }
  }
}
