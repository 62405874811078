@use '../abstracts/' as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  width: 100%;
}

body {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $black;
  color: $white;
  font-family: $font;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  width: 100%;
  margin: 0 auto;
  @include from-to(small, medium) {
    margin: 0 auto 5em auto;
  }
}

h1 {
  margin: 0;
}

a {
  // Remove decoration and default color
  text-decoration: none;
  color: inherit;
}

.spinner {
  // Create thunderbolt spinner
  border: 4px solid $white;
  border-radius: 50%;
  border-top: 4px solid $yellow;
  width: 2em;
  height: 2em;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  margin-top: 2em;

  // Create spin animation
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.error {
  color: $red;
  font-weight: bold;
}
