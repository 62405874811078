@use '../../abstracts' as *;

.signUp {
  width: 90%;
  margin: 1em auto;
  text-align: center;
  .title {
    h1 {
      margin: 0.2em auto;
      font-size: 2em;
    }
    margin: 1em auto 0 auto;
  }
  input[type='submit'] {
    width: 100%;
  }
  .login {
    a {
      color: $yellow;
      font-weight: bold;
    }
  }
  @include responde-to(medium) {
    .signUpForm {
      margin: 0 auto;
      width: 40%;
    }
  }
}
