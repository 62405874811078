.slide {
  opacity: 0; /* start with 0 opacity */

  &.left {
    transform: translateX(-100%); /* start off-screen to the left */
  }
  &.right {
    transform: translateX(100%);
  }
  transition: all 0.5s ease-in-out; /* add a transition effect */

  &.slide-in {
    opacity: 1; /* animate opacity to 1 */
    transform: translateX(0); /* animate to original position */
  }
}
