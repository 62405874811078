@use '../abstracts' as *;

.cartItem {
  margin: 1em auto;
  @include from-to(small, medium) {
    display: grid;
    height: 6em;
    grid-template-columns: 6em 2fr 1fr;
    grid-template-rows: 1fr 2fr;
    gap: 0 0.4em;

    .discountPrice,
    .productPrice,
    .Xremove {
      display: none;
    }

    img {
      grid-row: 1 / -1;
      grid-column: 1 / 1;
      width: 6em;
    }

    .productName {
      grid-row: 1 / 1;
      grid-column: 2 / 2;
      margin: 0;
      padding: 0;
    }

    .quantityInput {
      grid-row: 2 / -1;
      grid-column: 2 / 2;
    }

    .productTotal {
      grid-row: 1 / -1;
      grid-column: 3 / -1;
      justify-self: center;
    }

    p {
      font-weight: bold;
      small {
        font-weight: normal;
        padding-left: 0.5em;
      }
    }
  }

  @include responde-to(medium) {
    display: grid;
    grid-template-columns: 6em 2fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 98%;

    img {
      width: 100%;
    }

    p {
      font-weight: bold;
      small {
        font-weight: normal;
        display: block;
      }
    }

    .productName {
      text-align: left;
      margin: 0 0.5em;
    }

    .quantityInput {
      .remove {
        display: none;
      }
    }

    .Xremove {
      color: $yellow;
      background-color: $black;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
}
