@use '../abstracts' as *;

.header {
  width: 100%;

  // Font
  font-size: 0.9rem;
  font-weight: 700;

  // Flex
  display: flex;
  align-items: center;

  z-index: 100;

  img {
    padding: 0.4em 0 0 0.3em;
    width: 300px;

    @include from-to(small, menu) {
      width: 8em;
    }
  }

  // @include from-to(small, menu) {
  //   margin-top: 0.5rem;
  // }

  // @include responde-to(menu) {
  //   margin-top: 0.8em;
  //   letter-spacing: 1px;
  // }

  // Fix header (when scrolling)
  &.fixed {
    position: fixed;
    background: $black;
    top: 0;
    height: 5em;
    margin: 0;
  }

  nav {
    font-family: $fontAkira;
    display: inline;
    align-self: center;
    margin: 0 0 0 auto;
    @include from-to(small, menu) {
      a {
        margin: 1.5em auto;
        text-align: right;
        display: block;
        width: 90%;
      }
    }

    @include responde-to(menu) {
      line-height: 5em;
      text-align: right;
      a {
        margin: 0 1.5rem 0 0;
      }
    }

    .icon {
      font-size: 1.2em;
      color: $yellow;
    }
  }

  &__menu {
    order: 2;

    .active {
      border-bottom: 2px solid $yellow;
    }

    @include from-to(small, menu) {
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      right: -100%;
      background: $black;
      transition: right 350ms ease-in-out;
      font-size: 1.6rem;
      overflow-x: none;
      color: $white;

      &.open {
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
        right: 0;
      }
    }

    @include responde-to(menu) {
      margin: 0 1.5em 0 auto;
      #signUpLink {
        display: none;
      }
    }
  }

  &__openMenu {
    @include from-to(small, menu) {
      // padding: 0 0.5em;
      cursor: pointer;
      order: 3;
      font-size: 1.5em;
      margin: 0 0.5em 0 auto;
    }
    @include responde-to(menu) {
      display: none;
    }
  }

  &__closeMenu {
    @include from-to(small, menu) {
      width: 95%;
      display: block;
      text-align: right;
      margin: 0.5em 0.6em auto auto;
      cursor: pointer;
    }
    @include responde-to(menu) {
      display: none;
    }
  }
}
