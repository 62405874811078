@use '../abstracts' as *;

footer {
  width: 100%;
  margin-top: auto;
  padding: 1em 2em;
  display: flex;
  gap: 2em;
  justify-content: space-between;
  font-size: 0.8em;
  section {
    vertical-align: middle;

    p,
    a {
      display: flex;
      align-items: center;
      line-height: 1em;
      gap: 0.5em;
      @include from-to(small, medium) {
        justify-content: center;
      }
    }
  }

  @include from-to(small, medium) {
    flex-direction: column;
    align-items: center;
    gap: 1em;
    section {
      text-align: center;
    }
  }
}
