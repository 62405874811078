@use '../abstracts/' as *;

.orderItem {
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 1.5em auto;
  position: relative;
  img {
    width: 4em;
  }

  .orderQuantity {
    $side: 20px;
    position: absolute;
    left: calc(4em - 10px);
    top: -10px;
    width: $side;
    .rhombus {
      position: absolute;
      width: $side;
      height: $side;
      transform: rotate(45deg);
      background-color: $yellow;
    }
    small {
      position: absolute;
      color: $black;
      width: $side;
      height: $side;
      line-height: $side;
      text-align: center;
      font-size: 0.7em;
    }
  }

  .productTotal {
    margin: 0 0 0 auto;
  }

  &.ticket {
    p.productName {
      margin: 0;
      font-weight: bold;
      small {
        font-weight: normal;
        display: block;
      }
    }
  }

  @include from-to(small, medium) {
    p {
      font-weight: bold;
      small {
        font-weight: normal;
        padding-left: 0.5em;
      }
    }
  }

  @include responde-to(medium) {
    p {
      font-weight: bold;
      small {
        font-weight: normal;
        display: block;
      }
    }
  }
}
