@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url('../fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url('../fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: Akira;
  src: url('../fonts/Akira/akira.otf') format('opentype');
}

$font: 'Montserrat', sans-serif;
$fontAkira: 'Akira', sans-serif;
