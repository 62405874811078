@use '../abstracts/' as *;

main {
  margin: 0.5em auto 2em auto;
  min-height: 100vh;
  &:not(
      .store,
      .countdownPage,
      .checkoutPage,
      .homePage,
      .hallOfFamePage,
      .bestPage,
      .productPage,
      .ticketTappa
    ) {
    width: 90%;
  }
  h1 {
    font-family: $fontAkira;
    line-height: 1.3em;
  }
}
