@use '../../abstracts' as *;

.adminEvents {
  &__link {
    border-left: 2px solid $yellow;
    display: block;
    padding: 0.1rem 0.5rem;
    margin: 1rem 0;
  }
  &__back {
    color: $yellow;
    margin: 3em 0 0 0;
    display: block;
  }
}
