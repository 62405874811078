@use '../abstracts' as *;

.quantityInput {
  button {
    border: none;
    display: inline-block;
    cursor: pointer;
  }

  .sign {
    color: $black;
    background-color: $yellow;
    width: 2em;
    height: 2em;
    font-weight: bold;

    &:disabled {
      opacity: 0.5;
    }
  }
  p {
    display: inline-block;
    padding: 0 1em;
    text-align: center;
    width: 3em;
  }

  .remove {
    color: $yellow;
    background-color: $black;
    padding: 0;
    margin: 0;

    @include from-to(small, medium) {
      display: block;
    }
  }
}
