@use '../abstracts' as *;

.productCard {
  padding: 0;
  width: 19em;
  height: 30em;
  display: flex;
  flex-direction: column;
  margin: 1em calc((100% - 18em) / 2);
  text-align: center;

  //  Create cool box-shadow
  box-shadow: 0 0 0.7em 0.1em rgba(0, 0, 0, 0.5);
  .imgContainer {
    width: 100%;
    background-color: black;
    img {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  p {
    margin: 0;
    padding: 0;
    line-height: 1.6em;
  }

  .desc {
    margin: 0.2em;
    h3 {
      margin: 1em 0 0.1em 0;
      font-family: $fontAkira;
      font-size: 1.1em;
    }
    .zone {
      margin: 0 auto;
      color: $yellow;
    }
  }

  .price {
    margin: auto 1em 0.5em 1em;
    text-align: right;
    font-weight: bold;
    font-size: 1.3rem;
    font-family: $fontAkira;
    span.euro {
      font-family: $font;
    }
    p {
      display: inline-block;
    }
    .discount {
      & > span {
        color: $yellow;
        font-weight: bold;
      }
      p {
        padding: 0 1em;
        font-weight: normal;
        text-align: left;
        text-decoration: line-through;
      }
    }
  }
}
