@use '../../abstracts' as *;

.insurancePage {
  .content {
    margin: 2em auto;
    display: flex;
    flex-direction: column;
    div {
      .deleteTicket {
        display: block;
        margin: 1em 0;
      }
    }
    img {
      width: 100%;
      order: 2;
    }
    @include responde-to(medium) {
      flex-direction: row;
      justify-content: space-around;
      gap: 1em;
      img {
        width: 50%;
        order: 2;
      }
      div {
        width: 50%;
      }
    }
  }
}
