@use '../../abstracts' as *;

.bestPage {
  background-image: url($images + '/background/neutra.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 2em 1em 4em 1em;

  .links {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .tabella {
    margin: 4em auto;
    .scrollable-table {
      overflow-x: auto;
    }
    h2 {
      font-family: $fontAkira;
    }
    table {
      margin: 0 auto;
      text-transform: uppercase;
      // Style for the table
      font-size: clamp(0.8rem, 1vw, 1.5rem);
      width: 90%;

      th {
        background-color: $yellow;
        color: $black;
        padding: 0.5em 1em;
        font-size: 1.2em;
      }
      tr {
        border-bottom: 1px solid white;
      }
      td {
        padding: 0.5em 1em;
        font-size: 1.2em;
      }
    }
  }
  section {
    &.description {
      text-align: left;
    }
  }

  p {
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
    line-height: 1.5em;
    @include responde-to(medium) {
      margin: 2em auto;
      width: 70%;
    }
  }
}
