@use '../abstracts' as *;

.paymentForm {
  #paymentBtn {
    display: block;
    margin: 1em auto;
    width: 100%;
  }

  .invoiceLink {
    display: block;
    text-align: right;
  }
}
