@use '../../abstracts' as *;

.hallOfFamePage {
  background-image: url($images + '/background/neutra.jpg');
  // background-size: cover;
  // background-position: center;
  background-repeat: repeat;
  text-align: center;
  padding-top: 2em;
  p {
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
    line-height: 1.5em;
    margin: 2em auto;
    @include responde-to(medium) {
      width: 70%;
    }
  }

  .overall {
    margin: 2em auto;
    div {
      margin: 1em auto 3em auto;
      padding: 0 1em;
    }
    img {
      max-width: 100%;
    }
    h2 {
      font-family: $fontAkira;
      margin: 0 auto;
      padding: 0.5em auto;
    }
    h3 {
      font-family: $fontAkira;
      padding: 0;
      margin: 0;
    }
    a {
      color: $yellow;
      font-weight: bold;
      font-size: 1.2em;
      padding: 0.5em;
      display: block;
    }
  }

  .toggleProCards {
    font-size: 1.5em;
    margin: 2em auto;
  }

  .zoneBtn {
    display: block;
    border: none;
    background: transparent;
    border-left: 4px solid $yellow;
    border-right: 4px solid $yellow;
    font-family: $fontAkira;
    color: $white;
    width: 100%;
    text-align: center;
    padding: 2em;
    margin: 0 auto;
  }

  .proCard {
    h1 {
      font-family: $fontAkira;
      color: $yellow;
      margin-bottom: 0.1em;
      margin-top: 2em;
    }
    p {
      margin: 0 auto;
    }
    & > img {
      margin: 2em auto;
      width: 90%;
      @include responde-to(medium) {
        width: 50%;
      }
    }
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
    margin-top: 2em;
    h1 {
      font-family: $fontAkira;
    }
  }
  .weight {
    margin: 4em auto;
    h2 {
      font-family: $fontAkira;
    }
  }
  .card {
    img {
      width: 90%;
    }
    h2 {
      margin-bottom: 0.3em;
    }
    a {
      color: $yellow;
      font-weight: bold;
    }
  }
}
