@use '../abstracts' as *;

.ticketItem {
  margin: 1em auto;

  p {
    font-weight: bold;
    small {
      font-weight: normal;
      display: block;
    }
  }
  @include from-to(small, medium) {
    display: grid;
    height: 6em;
    grid-template-columns: 6em 2fr 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    gap: 0 0.4em;

    .discountPrice,
    .productPrice,
    .Xremove {
      display: none;
    }
    .remove {
      color: $yellow;
      border: none;
      background-color: $black;
      text-align: left;
      padding: 0;
      margin: auto auto 0 0;
      grid-row: 3 / 3;
    }

    img {
      grid-row: 1 / -1;
      grid-column: 1 / 1;
      width: 6em;
    }

    .productName {
      grid-row: 1 / 1;
      grid-column: 2 / 2;
      margin: 0;
      padding: 0;
    }

    .productTotal {
      grid-row: 1 / -1;
      grid-column: 3 / -1;
      justify-self: center;
    }
  }

  @include responde-to(medium) {
    display: grid;
    grid-template-columns: 6em 2fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 98%;

    img {
      width: 100%;
    }

    .productTotal {
      grid-column: 5 / 5;
    }

    .productName {
      text-align: left;
      margin: 0 0.5em;
    }

    .remove {
      display: none;
    }

    .Xremove {
      color: $yellow;
      background-color: $black;
      outline: none;
      border: none;
      cursor: pointer;
      grid-column: 6 / 6;
    }
  }
}
