@use '../abstracts/' as *;

.eventCard {
  padding: 0;
  width: 19em;
  height: 30em;
  display: flex;
  flex-direction: column;
  margin: 1em calc((100% - 18em) / 2);
  //  Create cool box-shadow
  box-shadow: 0 0 0.7em 0.1em rgba(0, 0, 0, 0.5);

  .not-active {
    opacity: 0.6;
  }
  .imgContainer {
    width: 100%;
    background-color: #0d0d0d;
    img {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  .desc {
    margin: 0 1em;
    h3 {
      margin: 0.7em 0;
    }
  }

  .price {
    margin: auto 1em 0.5em 1em;
    text-align: right;
    font-weight: bold;
    font-size: 1.3rem;
    font-family: $fontAkira;
    span.euro {
      font-family: $font;
    }
    p {
      display: inline-block;
    }
    .discount {
      & > span {
        color: $yellow;
        font-weight: bold;
      }
      p {
        padding: 0 1em;
        font-weight: normal;
        text-align: left;
        text-decoration: line-through;
      }
    }
  }
}
