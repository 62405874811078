@use '../abstracts' as *;

.modelInput {
  display: flex;
  gap: 0.2em;
  justify-content: flex-end;
  margin: 1em auto;
  a {
    width: 7em;
    height: 35px;
    line-height: 35px;
    border: 2px solid $white;
    text-align: center;
    vertical-align: middle;
    &.active {
      background-color: rgba($color: $yellow, $alpha: 1);
      border-color: $yellow;
      color: $black;
    }
  }
}
